import PalettePage from "../components/PalettePage";

function Palette() {
    return (
        <>
            <PalettePage/>
        </>
    );
}

export default Palette;