import AboutPage from "../components/AboutPage";

function About() {
  return (
    <>
      <AboutPage/>
    </>
  );
}

export default About;