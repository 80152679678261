import React from 'react';

function PrivacyPolicy() {
  return (
    <>
      <a href="https://www.iubenda.com/privacy-policy/12317639" className="iubenda-white privacy-policy iubenda-noiframe iubenda-embed iubenda-noiframe" title="Privacy Policy">Premi qui!</a>
    </>
  );
}

export default PrivacyPolicy;
