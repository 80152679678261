import FirstElement from "../components/FirstElement";

function Home() {
    return (
        <>
            <FirstElement/>
        </>
    );
}

export default Home;