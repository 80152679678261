import NoMatchPage from "../components/NoMatchPage";

function NoMatch() {
    return (
      <>
        <NoMatchPage/>
      </>
    );
  }

  export default NoMatch;